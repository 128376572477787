import { createRouter, createWebHistory } from 'vue-router'
import authRoutes from './auth'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/index-layout.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/main-layout.vue'),
        children: [
          {
            path: '/',
            name: 'dashboard',
            meta: { title: 'Dashboard' },
            component: () => import('@/views/dashboard/index-page.vue'),
          },
          {
            path: '/change-log',
            name: 'change-log',
            meta: { title: 'Riwayat Perubahan' },
            component: () => import('@/views/change-log/index-page.vue'),
          },
          {
            path: '/profile',
            children: [
              {
                path: '',
                name: 'profile',
                meta: { title: 'Akun Saya' },
                component: () => import('@/views/profile/index-page/main-page.vue'),
              },
              {
                path: 'email',
                name: 'profile-email',
                meta: { title: 'Akun Saya' },
                component: () => import('@/views/profile/change-email-page.vue'),
              },
              {
                path: 'password',
                name: 'profile-password',
                meta: { title: 'Akun Saya' },
                component: () => import('@/views/profile/change-password-page.vue'),
              },
            ],
          },
          {
            path: '/notification',
            name: 'notification',
            meta: { title: 'Notifikasi' },
            component: () => import('@/views/notification/index-page.vue'),
          },
          {
            path: '/task',
            name: 'task',
            meta: { title: 'Tugas Saya' },
            component: () => import('@/views/my-task/index-page.vue'),
          },
          {
            path: '/task/:taskId',
            name: 'task-detail',
            meta: { title: 'Detail Tugas' },
            props: true,
            component: () => import('@/views/task/view-page.vue'),
          },
        ],
      },
      authRoutes,
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

   // If a route with a title was found, set the document (page) title to that value.
   if (nearestWithTitle) {
    document.title = String(nearestWithTitle.meta.title) + ' - ' + import.meta.env.VITE_PROJECT_NAME
  } else if (previousNearestWithMeta) {
    document.title = String(previousNearestWithMeta.meta.title) + ' - ' + import.meta.env.VITE_PROJECT_NAME
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  const arrayTags = <{[key:string]: string}[]>nearestWithMeta.meta.metaTags
  arrayTags.map((tagDef) => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
